import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@mui/material'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import DeviceConnection from '../components/DeviceConnection'

export default function HomePage() {
  const theme = useTheme()

  return (
    <LayoutBase>
      <LayoutMain>
        <div className="relative w-full py-14">
          <div
            className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
              'bg-opacity-[0.02] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-800': !theme.isDark
            })}
          />
          <div
            className={clsx('absolute top-0 left-0 w-full h-[550px]', {
              'bg-opacity-[0.02] bg-white': theme.isDark,
              'bg-opacity-[0.05] bg-gray-800': !theme.isDark
            })}
          />
          <div className="relative py-12 md:py-48">
            <div className="mx-auto w-full md:max-w-[1100px] px-2 md:px-12">
              <DeviceConnection />
            </div>
          </div>
        </div>
      </LayoutMain>
    </LayoutBase>
  )
}
