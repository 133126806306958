import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import HomePage from './pages/Home'
import MessageSenderPage from './pages/MessageSender'
import MessageSchedulerPage from './pages/MessageScheduler'
import SettingsPage from './pages/Settings'
import SigninPage from './pages/Signin'
import ReportsPage from './pages/Reports'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/messages/send',
    element: <MessageSenderPage />
  },
  {
    path: '/messages/scheduler',
    element: <MessageSchedulerPage />
  },
  {
    path: '/reports',
    element: <ReportsPage />
  },
  {
    path: '/settings',
    element: <SettingsPage />
  },
  {
    path: '/signin',
    element: <SigninPage />
  }
])

export default function Routes() {
  return <RouterProvider router={router} />
}
