import React from 'react'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import MessageSender from '../components/MessageSender'

export default function MessageSenderPage() {
  return (
    <LayoutBase>
      <LayoutMain>
        <MessageSender />
      </LayoutMain>
    </LayoutBase>
  )
}
