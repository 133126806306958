import React from 'react'
import LayoutBase from '../layouts/Base'
import SigninForm from '../components/SigninForm'

export default function SigninPage() {
  return (
    <LayoutBase>
      <SigninForm />
    </LayoutBase>
  )
}
