import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material'

const CircularProgressCustom = ({color, ...props}) => {
  const theme = useTheme();

  return <CircularProgress style={{ color: color || theme.palette.primary.main }} {...props} />
}

export default CircularProgressCustom
