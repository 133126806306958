import React from 'react'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import MessageScheduler from '../components/MessageScheduler'

export default function MessageSchedulerPage() {
  return (
    <LayoutBase>
      <LayoutMain>
        <MessageScheduler />
      </LayoutMain>
    </LayoutBase>
  )
}
