import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import delay from 'delay'
import Snackbar from '@mui/material/Snackbar'
import { Button, Checkbox, Collapse, Paper, Radio, Switch, useTheme } from '@mui/material'
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery'
import WarningIcon from '@mui/icons-material/Warning'
import { PatternFormat } from 'react-number-format'
import hipersendService, { OUT_OF_HOURS_ACTIONS } from '../../services/hipersend'
import { ApiDataWrapperContext } from '../../contexts/ApiDataWrapper'
import Text from '../Text'
import TextFieldCustom from '../TextFieldCustom'
import CircularProgressCustom from '../CircularProgressCustom'

const SCHEDULES = [
  {
    day: 0,
    isEnabled: false,
    dayName: 'Domingo',
    startTime: '08:00',
    endTime: '16:00'
  },
  {
    day: 1,
    isEnabled: false,
    dayName: 'Segunda',
    startTime: '08:00',
    endTime: '18:00'
  },
  {
    day: 2,
    isEnabled: false,
    dayName: 'Terça',
    startTime: '08:00',
    endTime: '18:00'
  },
  {
    day: 3,
    isEnabled: false,
    dayName: 'Quarta',
    startTime: '08:00',
    endTime: '18:00'
  },
  {
    day: 4,
    isEnabled: false,
    dayName: 'Quinta',
    startTime: '08:00',
    endTime: '18:00'
  },
  {
    day: 5,
    isEnabled: false,
    dayName: 'Sexta',
    startTime: '08:00',
    endTime: '18:00'
  },
  {
    day: 6,
    isEnabled: false,
    dayName: 'Sábado',
    startTime: '08:00',
    endTime: '16:00'
  }
]

export default function MessageSchedule() {
  const { api, secret } = useContext(ApiDataWrapperContext)
  const theme = useTheme()
  const [loading, setLoading] = useState(true)
  const [isEnabled, setIsEnabled] = useState(false)
  const [schedules, setSchedules] = useState(SCHEDULES)
  const [outOfHoursAction, setOutOfHoursAction] = useState(undefined)
  const [messageError, setMessageError] = useState('')
  const [messageSent, setMessageSent] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const getData = async () => {
    try {
      setLoading(true)
      await delay(500)

      if(!api || !secret) return

      const response = await hipersendService.notificacoesInteligentes.getInformations(api, secret)
      const officeHourService = response.officeHourService

      if(!officeHourService) {
        setIsEnabled(false)
        setSchedules(SCHEDULES)
        setOutOfHoursAction(OUT_OF_HOURS_ACTIONS.send)
        return
      }

      setIsEnabled(officeHourService.active)
      setSchedules(SCHEDULES.map(schedule => {
        const scheduleDay = officeHourService.days.find(day => day.day === schedule.day)
        if(scheduleDay) {
          return {
            ...schedule,
            ...scheduleDay,
            isEnabled: true
          }
        }

        return schedule
      }))
      setOutOfHoursAction(officeHourService.outOfHoursAction)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setMessageError('Erro ao coletar dados do expediente')
    }
  }

  const handleInitialLoading = async () => {
  }

  const handleToggleEnabled = schedule => {
    setSchedules(schedules.map(_schedule => {
      if(_schedule.day === schedule.day) {
        return {
          ..._schedule,
          isEnabled: !schedule.isEnabled
        }
      }
      return _schedule
    }))
  }

  const handleOnChangeSchedule = (value, field, index) => {
    const schedulesCopy = [...schedules]

    if(!schedulesCopy[index]) {
      return
    }

    schedulesCopy[index][field] = value
    setSchedules(schedulesCopy)
  }

  const handleSubmit = async () => {
    try {
      setMessageError('')
      if(!api || !secret) return

      setIsSaving(true)
      await delay(800)

      const data = {
        isEnabled,
        outOfHoursAction,
        days: schedules.filter(schedule => schedule.isEnabled).map(schedule => ({
          day: schedule.day,
          startTime: schedule.startTime,
          endTime: schedule.endTime
        }))
      }

      const hourFormatHasError = isHourFormatValid(schedules.filter(schedule => schedule.isEnabled))
      if(hourFormatHasError) {
        setMessageError('Horário informado é inválido')
        return
      }

      await hipersendService.notificacoesInteligentes.changeOfficeHour(api, secret, data)
      setMessageSent('Expediente salvo')
      setIsSaving(false)
    } catch (error) {
      console.error(error)
      setMessageSent('Erro ao salvar expediente')
    }
  }

  const isHourFormatValid = (schedules) => {
    let hasError = false

    const hourRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

    for (const day of schedules) {
      if(!day.startTime.match(hourRegex) || !day.endTime.match(hourRegex)) {
        hasError = true
        break
      }
    }
    
    return hasError
  }

  useEffect(() => {
    getData()
  }, [api, secret])

  return (
    <div>
      <Snackbar
        open={!!messageSent}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setMessageSent(false)}
        message={messageSent}
      />
      <div className="relative mb-32">
        <div
          className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div
          className={clsx('absolute top-0 left-0 w-full h-[550px]', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div className="relative max-w-[750px] w-full mx-auto py-12 md:py-32">
          <div>
            <div className="flex space-x-2">
              <div>
                <BrowseGalleryIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
              </div>
              <div className="mt-1">
                <Text className="text-lg md:text-xl font-medium">
                  Expediente de envios
                </Text>
                <Text className="font-light text-sm">
                  Defina horários e dias específicos para realizar os envios das mensagens.
                </Text>
              </div>
            </div>
            <div className="mt-8">
              <Paper elevation={5}>
                <Collapse in={loading} timeout="auto" unmountOnExit>
                  <div className="h-[200px] flex justify-center items-center">
                    <CircularProgressCustom />
                  </div>
                </Collapse>
                <Collapse in={!loading} timeout="auto" unmountOnExit>
                  <div className="p-4 md:p-8">
                    <div>
                      <Text className="font-light text-lg">
                        Status
                      </Text>
                      <div className="flex items-center space-x-4">
                        <Switch onClick={() => setIsEnabled(!isEnabled)} checked={isEnabled} />
                        <Text>
                          Expediente de envios { isEnabled ? 'ativado' : 'desativado' }
                        </Text>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Text className="font-light text-lg">
                        Ação a ser executada
                      </Text>
                      <div>
                        <div className="flex items-center space-x-4">
                          <Radio
                            checked={outOfHoursAction === OUT_OF_HOURS_ACTIONS.abort}
                            onClick={() => setOutOfHoursAction(OUT_OF_HOURS_ACTIONS.abort)}
                          />
                          <Text>
                            Cancelar os envios
                          </Text>
                        </div>
                        <div className="flex items-center space-x-4">
                          <Radio
                            checked={outOfHoursAction === OUT_OF_HOURS_ACTIONS.send}
                            onClick={() => setOutOfHoursAction(OUT_OF_HOURS_ACTIONS.send)}
                          />
                          <Text>
                            Enviar no próximo expediente
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Text className="font-light text-lg">
                        Dias e horários do expediente
                      </Text>
                      <div>
                        <ol className="space-y-2">
                          {
                            schedules.map(schedule => {
                              return (
                                <li key={schedule.day}>
                                  <div className="grid grid-cols-12 gap-2 items-center">
                                    <div className="flex items-center space-x-4 col-span-12 md:col-span-4">
                                      <Checkbox
                                        checked={!!schedule.isEnabled}
                                        onClick={() => handleToggleEnabled(schedule)}
                                      />
                                      <Text>
                                        {schedule.dayName}
                                      </Text>
                                    </div>
                                    <div className="flex items-center col-span-5 md:col-span-3">
                                      <PatternFormat
                                        customInput={TextFieldCustom}
                                        onChange={e => handleOnChangeSchedule(e.target.value, 'startTime', schedule.day)}
                                        format="##:##"
                                        value={schedule.isEnabled ? schedule.startTime : ''}
                                        disabled={!schedule.isEnabled}
                                        valueIsNumericString={false}
                                      />
                                    </div>
                                    <div className="flex justify-center items-center h-[30px] col-span-2">
                                      <Text>até</Text>
                                    </div>
                                    <div className="flex items-center col-span-5 md:col-span-3">
                                      <PatternFormat
                                        customInput={TextFieldCustom}
                                        onChange={e => handleOnChangeSchedule(e.target.value, 'endTime', schedule.day)}
                                        format="##:##"
                                        value={schedule.isEnabled ? schedule.endTime : ''}
                                        disabled={!schedule.isEnabled}
                                        valueIsNumericString={false}
                                      />
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ol>
                      </div>
                    </div>
                    <Collapse in={!!messageError} timeout="auto" unmountOnExit>
                      <div className="mt-8">
                        <div className="p-1 bg-red-600 flex space-x-2">
                          <WarningIcon className="text-white" />
                          <Text className="text-white font-medium">{messageError}</Text>
                        </div>
                      </div>
                    </Collapse>
                    <div className="mt-8">
                      <Button disabled={isSaving} onClick={handleSubmit}>
                        {
                          isSaving
                          ? 'SALVANDO'
                          : 'SALVAR EXPEDIENTE'
                        }
                      </Button>
                    </div>
                  </div>
                </Collapse>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
