import React, { useEffect, useState } from 'react'
import { Collapse, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import Typography from '@mui/material/Typography'
import TextFieldCustom from '../TextFieldCustom'
import Text from '../Text'

const ConfirmationModal = ({ title, children, open, onClose, onConfirm, challengeQuestion = '', challengeAwnswer = '' }) => {
	const theme = useTheme()
	const [userAwnser, setUserAwnser] = useState('')
	const [isButtonLocked, setIsButtonLocked] = useState(!!challengeQuestion)

	const validateUserAwnser = (anwser) => {
		setUserAwnser(anwser)

		if(anwser === challengeAwnswer) {
			setIsButtonLocked(false)
		} else {
			setIsButtonLocked(true)
		}
	}

	useEffect(() => {
		const hasUserAnswer = !!userAwnser
		const hasChallengeQuestion = !!challengeQuestion
		const userAnswerDefaultValue = ''
		const isButtonLockedDefaultValue = true

		if(!open && hasUserAnswer) {
			setUserAwnser(userAnswerDefaultValue)
		}
		if(!open && hasChallengeQuestion && !isButtonLocked) {
			setIsButtonLocked(isButtonLockedDefaultValue)
		}
	}, [open])

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
				{
					challengeQuestion &&
					<div className="mt-4">
						<Text>{challengeQuestion}</Text>
						<div>
							<Text>Digite <span className="font-bold px-1 bg-gray-200 text-black rounded">{challengeAwnswer}</span> para prosseguir</Text>
						</div>
						<div className="mt-2 max-w-[300px] flex items-center space-x-4">
							<TextFieldCustom
								label="Resposta"
								value={userAwnser}
								onChange={e => validateUserAwnser(e.target.value)}
								placeholder={challengeAwnswer}
							/>
							<div>
								<Collapse in={isButtonLocked} timeout="auto" unmountOnExit>
									<LockOutlinedIcon style={{ fontSize: 32, color: 'gray' }} />
								</Collapse>
								<Collapse in={!isButtonLocked} timeout="auto" unmountOnExit>
									<LockOpenOutlinedIcon style={{ fontSize: 32, color: theme.palette.primary.main }} />
								</Collapse>
							</div>
						</div>
					</div>
				}
			</DialogContent>
			<DialogActions>
				<Button
					variant="text"
					onClick={() => onClose(false)}
					color="default"
				>
					Cancelar
				</Button>
				<Button
					variant="text"
					onClick={() => {
						onClose(false)
						onConfirm()
					}}
					color="primary"
					disabled={isButtonLocked}
				>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationModal
