import React, { useEffect, useRef } from 'react'
import { useTheme } from '@mui/material'
import clsx from 'clsx'
import anime from 'animejs'

export default function AnimatedScan({ children, animate = false, className = "w-[300px] h-[300px]" }) {
  const theme = useTheme()
  const containerRef = useRef()
  const scanBarRef = useRef()
  const scanLightRef = useRef()

  const playAnimation = () => {
    anime({
      targets: containerRef.current,
      height: ['0%', '100%'],
      opacity: [0, 1],
      delay: 100,
      duration: 800,
      easing: 'easeInOutExpo'
    })
    anime({
      targets: scanBarRef.current,
      opacity: [1, 1, 0],
      width: ['150%', '0%'],
      delay: 1000,
      duration: 200,
      easing: 'linear'
    })
    anime({
      targets: scanLightRef.current,
      opacity: [1, 0],
      height: [200, 0],
      delay: 500,
      duration: 200,
      easing: 'linear'
    })
  }

  useEffect(() => {
    if(animate) {
      playAnimation()
    }
  }, [animate])

  return (
    <div className={clsx(className, 'relative')}>
      <div
        className="absolute rounded w-full h-full pointer-events-none"
        style={{
          backgroundColor: theme.isDark ? "white" : "black",
          opacity: 0.05
        }}
      />
      <div ref={containerRef} className="relative h-[0%] opacity-0">
        <div className="overflow-hidden h-full">
          { children }
        </div>
        <div
          ref={scanLightRef}
          className="h-[200px] w-[135%] absolute -translate-x-1/2 bottom-0 left-1/2 pointer-events-none"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), ${theme.palette.primary.main})`
          }}
        />
        <div
          ref={scanBarRef}
          className="h-[10px] w-[150%] absolute -translate-x-1/2 bottom-0 left-1/2 pointer-events-none"
          style={{
            backgroundColor: theme.palette.primary.main
          }}
        />
      </div>
    </div>
  )
}
