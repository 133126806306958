import React, { useContext, useEffect, useRef, useState } from 'react'
import anime from 'animejs'
import delay from 'delay'
import clsx from 'clsx'
import moment from 'moment'
import { Button, Paper, Snackbar, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ShieldIcon from '@mui/icons-material/Shield'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import ChecklistIcon from '@mui/icons-material/Checklist'
import hipersendService from '../../services/hipersend'
import { ApiDataWrapperContext } from '../../contexts/ApiDataWrapper'
import Text from '../Text'
import AnimatedScan from '../AnimatedScan'
import CircularProgressCustom from '../CircularProgressCustom'
import ConfirmationModal from '../ConfirmationModal'

export default function DeviceConnection() {
  const { getCredentials } = useContext(ApiDataWrapperContext)
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isAuthenticated, setIsAuthencated] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const getInformations = async () => {
    try {
      const { api, secret } = getCredentials()
      const response = await hipersendService.notificacoesInteligentes.getInformations(api, secret)

      if(response?.statusSession === 'isLogged') {
        setIsAuthencated(true)
      } else {
        setIsAuthencated(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleGetInformationsInitial = async () => {
    await delay(800)
    getInformations()
    setIsInitialLoading(false)
  }

  const handleOnDisconnect = () => {
    setIsAuthencated(false)
    setSnackbarMessage('QR code expirado com sucesso')
  }

  useEffect(() => {
    const interval = setInterval(getInformations, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    handleGetInformationsInitial()
  }, [])

  return (
    <div>
      <Snackbar
        open={!!snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
      {
        !isInitialLoading
        ?
        <div>
          {
            isAuthenticated
            ? <DeviceConnected onDisconnect={handleOnDisconnect} />
            : <DeviceDisconnected />
          }
        </div>
        :
        <div className="h-[400px] flex justify-center items-center">
          <CircularProgressCustom />
        </div>
      }
      <div className="mt-12">
        <Paper elevation={5}>
          <Paper elevation={1}>
            <div className="max-w-[1000px] mx-auto w-full">
              <div className="p-4 md:p-12">
                <div>
                  <div className="flex items-center space-x-2">
                    <ChecklistIcon style={{ fontSize: 40 }} />
                    <Text className="text-xl">
                      Dicas de utilização e termos de uso
                    </Text>
                  </div>
                  <div className="mt-8">
                    <Text>
                      Faça a leitura do QRCode com seu Telefone/WhatsApp, para realizar os envios através do mesmo. Atente-se a ler as dicas mencionadas abaixo para um correto funcionamento do sistema e também para prevenir bloqueios do seu WhatsApp.
                    </Text>
                  </div>
                </div>
                <div className="mt-12">
                  <Text className="text-xl">
                    Como não usar a api
                  </Text>
                  <ul>
                    <li>
                      <div className="flex space-x-2">
                        <DoDisturbIcon />
                        <Text>Não utilizar números que já tenham sido banidos</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <DoDisturbIcon />
                        <Text>Não clique em "Sair de todas as sessões" no celular</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <DoDisturbIcon />
                        <Text>Não ative um número e já faça envios em massa. Considere usar números ativos a mais de 2 meses no WhatsApp</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <DoDisturbIcon />
                        <Text>Seu número pode ser banido se muitos clientes denunciarem como SPAM</Text>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="mt-12">
                  <Text className="text-xl">
                    Dicas de uso para sua API
                  </Text>
                  <ul>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Conecte-se ao WhatsApp Web antes de conectar a API</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Recomendamos números ativos há mais de 2 meses no WhatsApp</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Use versões oficiais do WhatsApp (WhatsApp ou WhatsApp Business)</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Entre em grupos do WhatsApp (Grupos do seu provedor e/uo grupo de suporte da sua empresa)</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Troque mensagens com pelo menos 5 contatos e estabeleça um diálogo breve com os mesmos</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Poste status no WhatsApp, aqui não se preocupe com as visualizações nesses status, o intuito é apenas movimentar sua conta</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Após ler o QR Code, aguarde a sincronização completar e mostrar autenticado</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Não use WhatsApp clonado por apps de terceiros (SuperClone, Parallel, etc)</Text>
                      </div>
                    </li>
                    <li>
                      <div className="flex space-x-2">
                        <CheckIcon />
                        <Text>Mantenha seu WhatsApp atualizado! Versões antigas podem causar problemas na API</Text>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Paper>
        </Paper>
      </div>
    </div>
  )
}

function DeviceDisconnected() {
  const { getCredentials } = useContext(ApiDataWrapperContext)
  const containerRef = useRef()
  const [animateQrCodeScan, setAnimateQrCodeScan] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [qrCodeUpdatedAt, setQrCodeUpdatedAt] = useState('')
  const [apiNotFoundError, setApiNotFoundError] = useState(false)
  const [getApiQrCodeInterval, setApiQrCodeInterval] = useState(undefined)

  const handleAnimation = async () => {
    await delay(300)
    anime({
      targets: containerRef.current,
      opacity: [0, 1],
      translateY: [20, 0],
      scale: [0.99, 1]
    })
    await delay(300)
    setAnimateQrCodeScan(true)
  }

  const getApiQrCode = async () => {
    try {
      setApiNotFoundError(false)
      const { api, secret } = getCredentials()
      const response = await hipersendService.notificacoesInteligentes.getQrCode(api, secret)
      setQrCode(response.base64Qr)
      setQrCodeUpdatedAt(response.updated_at)
    } catch (error) {
      console.error(error)
      const errorMessage = error?.response?.data?.error

      if(errorMessage === 'Não foi possível encontrar a instância') {
        setApiNotFoundError(true)
      }
    }
  }

  useEffect(() => {
    handleAnimation()
    getApiQrCode()
  }, [])

  useEffect(() => {
    const interval = setInterval(getApiQrCode, 5000)
    setApiQrCodeInterval(interval)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if(!apiNotFoundError) return
    clearInterval(getApiQrCodeInterval)
  }, [apiNotFoundError])

  if(apiNotFoundError) {
    return (
      <div>
        api não encontrada
      </div>
    )
  }

  return (
    <div ref={containerRef} className="opacity-0">
      <Paper elevation={5}>
        <Paper elevation={0}>
          <div className="w-full mx-auto p-12">
            <div className="md:flex space-y-8 md:space-y-0 md:space-x-12">
              <div>
                <div className="hidden md:block">
                  <AnimatedScan animate={animateQrCodeScan} className="w-[400px] h-[400px]">
                    <div className="w-[400px] h-[400px]">
                      <img src={qrCode} />
                    </div>
                  </AnimatedScan>
                </div>
                <div className="block md:hidden flex justify-center">
                  <AnimatedScan animate={animateQrCodeScan} className="w-[300px] h-[300px]">
                    <div className="w-[300px] h-[300px]">
                      <img src={qrCode} />
                    </div>
                  </AnimatedScan>
                </div>
                <div className="mt-2">
                  <Text className="text-sm font-medium">
                    QR code atualizado em {moment(qrCodeUpdatedAt).format('DD/MM/YYYY [às] HH:mm:ss')}
                  </Text>
                </div>
              </div>
              <div className="w-full pt-4">
                <Text className="font-light text-2xl">Conectar aparelho</Text>
                <ol className="space-y-6 mt-6">
                  <li className="">
                    <Text className="font-light text-xl">
                      <span className="font-black">1.</span> Abra o Whatsapp no seu celular
                    </Text>
                  </li>
                  <li className="">
                    <Text className="font-light text-xl">
                      <span className="font-black">2.</span> Toque em Mais opções <span>:</span> ou Configurações <span>O</span> e selecione Aparelhos conectados.
                    </Text>
                  </li>
                  <li className="">
                    <Text className="font-light text-xl">
                      <span className="font-black">3.</span> Toque em Conectar um aparelho.
                    </Text>
                  </li>
                  <li className="">
                    <Text className="font-light text-xl">
                      <span className="font-black">4.</span> Aponte o celular para esta tela para capturar o QR code.
                    </Text>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Paper>
      </Paper>
    </div>
  )
}

function DeviceConnected({ onDisconnect }) {
  const { getCredentials, server } = useContext(ApiDataWrapperContext)
  const theme = useTheme()
  const phoneRef = useRef()
  const serverRef = useRef()
  const pipeRef = useRef()
  
  const [restartApiButtonEnabled, setRestartApiButtonEnabled] = useState(true)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isOpenConfirmationModalQrCodeExpire, setIsOpenConfirmationModalQrCodeExpire] = useState(false)
  const [isOpenConfirmationModalRestartApi, setIsOpenConfirmationModalRestartApi] = useState(false)

  const handleAnimation = async () => {
    anime({
      targets: phoneRef.current,
      translateX: [250, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo'
    })
    anime({
      targets: serverRef.current,
      translateX: [-250, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo'
    })
    await delay(500)
    anime({
      targets: pipeRef.current,
      translateY: [10, 0],
      opacity: [0, 1],
      easing: 'easeOutExpo'
    })
  }

  const handleExpireQrCode = async () => {
    try {
      const { api, secret } = getCredentials()
      await hipersendService.notificacoesInteligentes.expireQrCode(api, secret)
      onDisconnect()
    } catch (error) {
      console.error(error)
    }
  }

  const handleRestartApi = async () => {
    try {
      const { api, secret } = getCredentials()
      setRestartApiButtonEnabled(false)
      await hipersendService.notificacoesInteligentes.restartApi(api, secret)
      setSnackbarMessage('Api reiniciada com sucesso')
      setTimeout(() => {
        setRestartApiButtonEnabled(true);
      }, 300000);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleAnimation()
  }, [])

  return (
    <div>
      <ConfirmationModal
        open={isOpenConfirmationModalQrCodeExpire}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Expirar QR code
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para expirar o QR code atual"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalQrCodeExpire(false)
        }}
        onConfirm={handleExpireQrCode}
      >
        <Text>Deseja realmente expirar o QR code?</Text>
      </ConfirmationModal>
      <ConfirmationModal
        open={isOpenConfirmationModalRestartApi}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Reiniciar api
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para reiniciar a api"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalRestartApi(false)
        }}
        onConfirm={handleRestartApi}
      >
        <Text>Deseja realmente reiniciar a api?</Text>
      </ConfirmationModal>
      <Snackbar
        open={!!snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
      {/* illustration */}
      <div className="flex items-center justify-between">
        {/* cellphone */}
        <div ref={phoneRef} className="opacity-0">
          <div
            className={clsx('relative w-[120px] md:w-[240px] h-[190px] md:h-[370px] rounded-md md:rounded-lg', {
              'bg-[#202124ff]': theme.isDark,
              'bg-[#ccccccff]': !theme.isDark,
            })}
          >
            <div
              className={clsx('absolute left-1/2 -translate-x-1/2 top-[5%] w-[30%] h-[10px] rounded-md bg-white', {
                'opacity-[0.1]': theme.isDark,
                'opacity-[0.7]': !theme.isDark
              })}
            />
            <div className="absolute top-[12%] -translate-x-1/2 left-1/2 rounded-md md:rounded-lg h-[70%] w-[75%]">
              <div
                className={clsx('relative w-full h-full bg-white  rounded-md md:rounded-lg', {
                  'opacity-[0.1]': theme.isDark,
                  'opacity-[0.7]': !theme.isDark
                })}
              />
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[50px] md:w-[100px] h-[50px] md:h-[100px] bg-[#00ccffff] rounded-full flex justify-center items-center">
                <div className="hidden md:block">
                  <CheckIcon className="text-white" style={{ fontSize: 60 }} />
                </div>
                <div className="block md:hidden">
                  <CheckIcon className="text-white" style={{ fontSize: 30 }} />
                </div>
              </div>
            </div>
            <div
              className={clsx('absolute left-1/2 -translate-x-1/2 bottom-[5%] w-[20px] md:w-[30px] h-[20px] md:h-[30px] rounded-full bg-white', {
                'opacity-[0.1]': theme.isDark,
                'opacity-[0.7]': !theme.isDark
              })}
            />
          </div>
        </div>
        {/* connection */}
        <div ref={pipeRef} className="relative w-full opacity-0">
          <div
            className={clsx('relative w-full h-[20px] overflow-hidden', {
              'bg-[#202124ff]': theme.isDark,
              'bg-[#20212442]': !theme.isDark
            })}
          >
            <div className="absolute w-[40%] h-full animate-left-to-right animate-infinite animate-duration-[0.6s] bg-[#00ccffff]" />
          </div>
          <div className="absolute top-[120px] md:top-[60px] w-full">
            <div className="flex items-center justify-center space-x-2">
              <ShieldIcon style={{ color: '#00ccffff', fontSize: 35 }} />
              <p className="text-[#00ccffff] font-black text-xs md:text-3xl">CONECTADO</p>
            </div>
          </div>
        </div>
        {/* server */}
        <div ref={serverRef} className="opacity-0">
          <div
            className={clsx('relative w-[120px] md:w-[240px] h-[190px] md:h-[370px] rounded-md md:rounded-lg', {
              'bg-[#202124ff]': theme.isDark,
              'bg-[#ccccccff]': !theme.isDark
            })}
          >
            <div className="p-4 md:p-8 flex flex-col h-full">
              <div className="grid grid-cols-12 gap-[3px] md:gap-2">
                <div
                  className={clsx('col-span-10 h-[20px] md:h-[50px] rounded-md', {
                    'bg-white opacity-[0.05]': theme.isDark,
                    'bg-gray-800 opacity-[0.2]': !theme.isDark
                  })}
                >

                </div>
                <div className="col-span-2 grid grid-cols-1 gap-[3px] md:gap-2 h-[20px] md:h-[50px]">
                  <div className="bg-[#00ccffff] rounded-md"></div>
                  <div className="bg-[#00ccffff] rounded-md"></div>
                </div>
              </div>
              <div className="my-auto">
                <Text
                  className={clsx('text-xs md:text-2xl font-black text-center', {
                    'text-white opacity-[0.5]': theme.isDark,
                    'text-black opacity-[0.5]': !theme.isDark
                  })}
                >SERVIDOR HS</Text>
                <Text
                  className={clsx('md:text-4xl font-black text-center', {
                    'text-white': theme.isDark,
                    'text-black opacity-[0.5]': !theme.isDark
                  })}
                >{server}</Text>
              </div>
              <div className="flex justify-end space-x-1 md:space-x-2">
                <div className="w-[10px] md:w-[20px] h-[10px] md:h-[20px] bg-emerald-600 rounded-full" />
                <div className="w-[10px] md:w-[20px] h-[10px] md:h-[20px] bg-emerald-600 rounded-full" />
                <div className="w-[10px] md:w-[20px] h-[10px] md:h-[20px] bg-emerald-600 rounded-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 flex justify-center space-x-8">
        <Button onClick={() => setIsOpenConfirmationModalQrCodeExpire(true)}>
          EXPIRAR QRCODE
        </Button>
        <Button onClick={() => setIsOpenConfirmationModalRestartApi(true)} disabled={!restartApiButtonEnabled}>
          REINICIAR API
        </Button>
      </div>
    </div>
  )
}
