import React, { useContext, useEffect, useState } from 'react'
import { Paper, Collapse, useTheme } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import TimelineIcon from '@mui/icons-material/Timeline'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import LogoutIcon from '@mui/icons-material/Logout'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import TuneIcon from '@mui/icons-material/Tune'
import CreateIcon from '@mui/icons-material/Create'
import { Link, useSearchParams } from 'react-router-dom'
import hipersendService from '../services/hipersend'
import { ApiDataWrapperContext } from '../contexts/ApiDataWrapper'
import { AuthContext } from '../contexts/Auth'
import Text from '../components/Text'
import Logo from '../components/Logo'

export default function LayoutMain({ children }) {
  const { logout } = useContext(AuthContext)
  const { setApi, setSecret, api, secret, server, phone, isSuspended } = useContext(ApiDataWrapperContext)
  const theme = useTheme()

  const [queryParameters] = useSearchParams()
  const [notifications, setNotifications] = useState([])

  const getNotifications = async () => {
    try {
      const notifications = await hipersendService.notificacoesInteligentes.getNotifications()
      setNotifications(notifications)
    } catch (error) {
      console.error(error)

      if(error?.response?.data?.error === 'Não foi possível encontrar a instância') {
        logout()
        return
      }
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    const _api = queryParameters.get('api')
    const _secret = queryParameters.get('secret')

    if(!_api || !_secret) {
      return
    }

    setApi(_api)
    setSecret(_secret)
  }, [queryParameters.get('api'), queryParameters.get('secret')])

  useEffect(() => {
    const _api = window.localStorage.getItem('api')
    const _secret = window.localStorage.getItem('secret')

    if(!_api || !_secret) {
      return
    }

    setApi(_api)
    setSecret(_secret)
  }, [])

  if(!api || !secret) {
    return (
      <div className="h-full w-full flex justify-center items-center" style={{ backgroundColor: theme.palette.background.main }}>
        <Logo className="w-[150px]" />
      </div>
    )
  }

  return (
    <div className="min-h-full flex" style={{ backgroundColor: theme.palette.background.main }}>
      <div className="hidden md:block h-full">
        <div className="w-[300px]">
          <aside className="hidden md:block fixed top-0 left-0 w-[300px] h-full shadow-[5px_0px_10px_0px_rgba(0,0,0,0.3)]">
            <Paper square elevation={0} style={{ height: "100%", overflowY: "scroll" }}>
              <div className="min-h-screen w-full pt-4 flex flex-col pb-8">
                <div className="mb-4 px-4">
                  <Logo className="w-[120px]" />
                  <p className="font-medium text-sm opacity-[0.7]">Notificações Inteligentes</p>
                  <p className="font-light text-xs opacity-[0.5]">Painel v2.0.0.beta</p>
                </div>
                <MenuList>
                  <MenuItem component={Link} to="/">
                    <ListItemIcon>
                      <SpaceDashboardIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Home
                      </Text>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to="/messages/send">
                    <ListItemIcon>
                      <CreateIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Enviar
                      </Text>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to="/reports">
                    <ListItemIcon>
                      <TimelineIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Relatórios
                      </Text>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to="/messages/scheduler">
                    <ListItemIcon>
                      <WatchLaterIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Expediente de envios
                      </Text>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to="/settings">
                    <ListItemIcon>
                      <TuneIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Configurações
                      </Text>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText className="py-3">
                      <Text className="font-medium">
                        Sair
                      </Text>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
                <div className="mt-16 px-5">
                  <p>Detalhes da API</p>
                  <div className="space-y-3 mt-4">
                    <div>
                      <p className="text-xs font-light">API</p>
                      <p className="font-medium">{api}</p>
                    </div>
                    <div>
                      <p className="text-xs font-light">SECRET</p>
                      <p className="font-medium">{secret}</p>
                    </div>
                    <div>
                      <p className="text-xs font-light">SERVIDOR</p>
                      <p className="font-medium">servidor {server}</p>
                    </div>
                    <div>
                      <p className="text-xs font-light">TELEFONE</p>
                      <p className="font-medium">{phone}</p>
                    </div>
                    <div>
                      <p className="text-xs font-light">STATUS</p>
                      <p className="font-bold">
                        {
                          isSuspended === undefined
                          ? '-'
                          : isSuspended
                            ? 'DESATIVADO'
                            : 'ATIVADO'
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </aside>
        </div>
      </div>
      <div className="w-full">
        <Collapse in={notifications.length > 0} timeout="auto" unmountOnExit>
          {
            notifications
              .filter(notifications => notifications.isActive)
              .map((notification, index) => (
                <div key={index} className="p-12 bg-white bg-opacity-[0.1] rounded-sm">
                  <div className="flex space-x-4">
                    <AnnouncementIcon style={{ fontSize: 35 }} className="scale-x-[-1]" />
                    <div>
                      <Text className="font-black text-xl">{notification.title}</Text>
                      <Text>{notification.content}</Text>
                    </div>
                  </div>
                </div>
            ))
          }
        </Collapse>
        {children}
      </div>
    </div>
  )
}
