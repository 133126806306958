import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Collapse, Paper, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import delay from 'delay'
import moment from 'moment'
import anime from 'animejs'
import Chart from 'react-apexcharts'
import Tooltip from '@mui/material/Tooltip'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import CheckIcon from '@mui/icons-material/Check'
import StorageIcon from '@mui/icons-material/Storage'
import LabelIcon from '@mui/icons-material/Label'
import messagesLib from '../../libs/messages'
import { ApiDataWrapperContext } from '../../contexts/ApiDataWrapper'
import hipersendService from '../../services/hipersend'
import Text from '../Text'
import CircularProgressCustom from '../CircularProgressCustom'
import ConfirmationModal from '../ConfirmationModal'
import WpBgLight from '../../assets/wp-bg-light.png'
import WpBgDark from '../../assets/wp-bg-dark.png'

const OFFSET_PAGINATION = 30

export default function Reports() {
  const { api, secret, server } = useContext(ApiDataWrapperContext)
  const theme = useTheme()
  const containerRef = useRef()

  const [reportData, setReportData] = useState([])
  const [messages, setMessages] = useState([])
  const [withinWorkingHours, setWithinWorkingHours] = useState(false)
  const [statistics, setStatistics] = useState({
    success: undefined,
    error: undefined,
    queue: undefined
  })
  const [page, setPage] = useState(0)
  const [isLoadingMessages, setLoadingMessages] = useState(false)
  const [isOpenConfirmationModalClearQueue, setIsOpenConfirmationModalClearQueue] = useState(false)
  const [isOpenConfirmationModalClearReport, setIsOpenConfirmationModalClearReport] = useState(false)
  const [isOpenConfirmationModalResendMessagesWithErrors, setIsOpenConfirmationModalResendMessagesWithErrors] = useState(false)
  const [isOpenConfirmationModalDeleteMessagesWithErrors, setIsOpenConfirmationModalDeleteMessagesWithErrors] = useState(false)

  const handleAnimation = () => {
    anime({
      targets: containerRef.current.children,
      translateY: [10, 0],
      opacity: [0, 1],
      delay: anime.stagger(100),
      scale: [0.99, 1]
    })
  }

  const getApiData = async () => {
    try {
      if(!api || !secret) return
      const response = await hipersendService.notificacoesInteligentes.getInformations(api, secret)
      setReportData(response.shippingReport)
      setWithinWorkingHours(response.officeHourService.withinWorkingHours)
    } catch (error) {
      console.error(error)
    }
  }

  const getMessages = async ({ page = 0 } = {}) => {
    try {
      if(!api || !secret) return
      const offset = page * OFFSET_PAGINATION

      setLoadingMessages(true)
      await delay(800)
      const response = await hipersendService.notificacoesInteligentes.getMessages(api, secret, { offset })
      setMessages(response?.messages || [])
      setLoadingMessages(false)
    } catch (error) {
      console.error(error)
      setLoadingMessages(false)
    }
  }

  const getMessagesStatistics = async () => {
    try {
      if(!api || !secret) return

      const response = await hipersendService.notificacoesInteligentes.getMessagesStatistics(api, secret)
      setStatistics(response)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClearQueue = async () => {
    try {
      if(!api || !secret) return
      await hipersendService.notificacoesInteligentes.clearQueue(api, secret)
      getMessages({ page })
      getMessagesStatistics()
    } catch (error) {
      console.error(error)
    }
  }

  const handleClearReport = async () => {
    try {
      if(!api || !secret) return
      await hipersendService.notificacoesInteligentes.clearReport(api, secret)
      getMessages({ page })
      getMessagesStatistics()
    } catch (error) {
      console.error(error)
    }
  }

  const handleResendMessagesWithErrors = async () => {
    try {
      if(!api || !secret) return
      await hipersendService.notificacoesInteligentes.resendMessagesWithErrors(api, secret)
      getMessages({ page })
      getMessagesStatistics()
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteMessagesWithErrors = async () => {
    try {
      if(!api || !secret) return
      await hipersendService.notificacoesInteligentes.deleteMessagesWithErrors(api, secret)
      getMessages({ page })
      getMessagesStatistics()
    } catch (error) {
      console.error(error)
    }
  }

  const paginate = page => {
    getMessages({ page })

    if(page >= 0) {
      setPage(page)
    }
  }

  const nextPage = () => {
    const currentPage = page + 1
    paginate(currentPage)
  }

  const previousPage = () => {
    const currentPage = page - 1
    paginate(currentPage)
  }

  useEffect(() => {
    handleAnimation()
  }, [])

  useEffect(() => {
    getApiData()
    getMessages()
    getMessagesStatistics()
  }, [api, secret])

  return (
    <>
      <ConfirmationModal
        open={isOpenConfirmationModalClearReport}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Limpar mensagens
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para prosseguir com a limpeza das mensagens"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalClearReport(false)
        }}
        onConfirm={handleClearReport}
      >
        <Text className="font-bold">Essa ação é irreversível.</Text>
        <Text>Deseja realmente limpar as mensagens?</Text>
      </ConfirmationModal>
      <ConfirmationModal
        open={isOpenConfirmationModalClearQueue}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Limpar fila
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para prosseguir com a limpeza da fila"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalClearQueue(false)
        }}
        onConfirm={handleClearQueue}
      >
        <Text className="font-bold">Essa ação é irreversível.</Text>
        <Text>Deseja realmente limpar as mensagens que estão na fila?</Text>
      </ConfirmationModal>
      <ConfirmationModal
        open={isOpenConfirmationModalResendMessagesWithErrors}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Reenviar mensagens com erro
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para prosseguir com o reenvio das mensagens com erro"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalResendMessagesWithErrors(false)
        }}
        onConfirm={handleResendMessagesWithErrors}
      >
        <Text>Deseja realmente reenviar as mensagens com erro?</Text>
      </ConfirmationModal>
      <ConfirmationModal
        open={isOpenConfirmationModalDeleteMessagesWithErrors}
        title={
          <div className="flex items-center space-x-2">
            <Text component="span">
              <GppMaybeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
            </Text>
            <Text component="span" className="font-bold text-xl">
              Deletar mensagens com erro
            </Text>
          </div>
        }
        challengeQuestion="Digite a palavra PROSSEGUIR para prosseguir com a remoção das mensagens com erro"
        challengeAwnswer="PROSSEGUIR"
        onClose={() => {
          setIsOpenConfirmationModalDeleteMessagesWithErrors(false)
        }}
        onConfirm={handleDeleteMessagesWithErrors}
      >
        <Text className="font-bold">Essa ação é irreversível.</Text>
        <Text>Deseja realmente remover as mensagens com erro?</Text>
      </ConfirmationModal>
      <div className="relative px-4">
        <div
          className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div
          className={clsx('absolute top-0 left-0 w-full h-[550px]', {
            'bg-opacity-[0.02] bg-white': theme.isDark,
            'bg-opacity-[0.05] bg-gray-800': !theme.isDark
          })}
        />
        <div ref={containerRef} className="relative max-w-[1100px] w-full mx-auto py-12 md:py-32">
          <div>
            <Text className="">VISÃO GERAL</Text>
            <div className="mt-2">
              <Paper elevation={4}>
                <div className="grid md:grid-cols-6">
                  <div className="px-6 py-12 md:col-span-1 bg-emerald-600">
                    <div className="flex flex-col justify-center h-full px-4">
                      <Text className="font-light text-white">SUCESSO</Text>
                      <Text className="text-4xl font-black text-white">
                        {
                          statistics.success === undefined
                          ? <span className="block w-full h-[20px] bg-white bg-opacity-[0.5]"></span>
                          : statistics.success
                        }
                      </Text>
                      <Text className="text-xs font-light text-white">Mensagens</Text>
                    </div>
                  </div>
                  <div
                    className={clsx('px-6 py-12 md:col-span-1 bg-opacity-[0.2]', {
                      'bg-white': theme.isDark,
                      'bg-gray-800': !theme.isDark
                    })}
                  >
                    <div className="flex flex-col justify-center h-full px-4">
                      <Text className="font-light">FILA</Text>
                      <Text className="text-4xl">
                        {
                          statistics.queue === undefined
                          ? <span className="block w-full h-[20px] bg-white bg-opacity-[0.5]"></span>
                          : statistics.queue
                        }
                      </Text>
                      <Text className="text-xs font-light">Mensagens</Text>
                    </div>
                  </div>
                  <div
                    className={clsx('px-6 py-12 md:col-span-1 bg-opacity-[0.1]', {
                      'bg-white': theme.isDark,
                      'bg-gray-800': !theme.isDark
                    })}
                  >
                    <div className="flex flex-col justify-center h-full px-4">
                      <Text className="font-light">ERRO</Text>
                      <Text className="text-4xl">
                        {
                          statistics.error === undefined
                          ? <span className="block w-full h-[20px] bg-white bg-opacity-[0.5]"></span>
                          : statistics.error
                        }
                      </Text>
                      <Text className="text-xs font-light">Mensagens</Text>
                    </div>
                  </div>
                  <div className="px-6 py-12 md:col-span-3">
                    <div className="flex space-x-4">
                      <div>
                        <StorageIcon style={{ fontSize: 40 }} />
                      </div>
                      <div>
                        <Text className="font-light">API</Text>
                        <Text className="text-sm md:text-2xl">{api}</Text>
                        <Text className="flex items-center space-x-2">
                          <span className="block">Servidor</span>
                          {server !== '' ? <span>{server}</span> : <span className="block h-[20px] w-[20px] bg-white bg-opacity-[0.2]" />}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
          <div className="mt-12">
            <Text className="">GRÁFICO</Text>
            <Collapse in={!!reportData.data} timeout="auto" unmountOnExit>
              {
                reportData.data &&
                <div className="mt-2">
                  <Paper elevation={5}>
                    <div>
                      <Chart
                        options={{
                          chart: {
                            type: 'bar',
                            height: 350,
                            foreColor: theme.isDark ? '#fff' : '#000',
                            toolbar: {
                              show: false
                            },
                            grid: {
                              xaxis: {
                                lines: {
                                  show: false
                                }
                              },   
                              yaxis: {
                                lines: {
                                  show: false
                                }
                              }
                            }
                          },
                          colors: ['#059669', '#eb3c3c'],
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: '55%',
                              endingShape: 'rounded'
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            enabledOnSeries: undefined,
                            formatter: function (val, opts) {
                              return val;
                            },
                            style: {
                              fontSize: '14px',
                              fontFamily: 'Helvetica, Arial, sans-serif',
                              fontWeight: 'bold',
                              colors: [theme.isDark ? '#fff' : '#000']
                            }
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                          },
                          xaxis: {
                            categories: reportData?.dateTimes?.map(date => moment(date).format('DD/MM/YYYY')) || [],
                          },
                          yaxis: {
                            title: {
                              text: 'Envios'
                            }
                          },
                          fill: {
                            opacity: 1
                          },
                          tooltip: {
                            y: {
                              formatter: function (val) {
                                return val
                              }
                            }
                          }
                        }}
                        series={[{
                          name: 'Envios com sucesso',
                          data: reportData.data ? reportData.data.map(([day, error, success]) => success) : []
                        }, {
                          name: 'Envios com erro',
                          data: reportData.data ? reportData.data.map(([day, error, success]) => error) : []
                        }]}
                        type="bar"
                        height={350}
                      />
                    </div>
                  </Paper>
                </div> 
              }
            </Collapse>
          </div>
          <div className="mt-12">
            <div className="md:flex md:justify-center space-y-4 md:space-y-0 md:space-x-12">
              <Button onClick={() => setIsOpenConfirmationModalClearQueue(true)} startIcon={<LabelIcon style={{ fontSize: 25 }} />}>
                <Text component="span">
                  LIMPAR FILA
                </Text>
              </Button>
              <Button onClick={() => setIsOpenConfirmationModalClearReport(true)} startIcon={<LabelIcon style={{ fontSize: 25 }} />}>
                <Text component="span">
                  LIMPAR MENSAGENS
                </Text>
              </Button>
              <Button onClick={() => setIsOpenConfirmationModalResendMessagesWithErrors(true)} startIcon={<LabelIcon style={{ fontSize: 25 }} />}>
                <Text component="span">
                  REENVIAR MENSAGENS COM ERRO
                </Text>
              </Button>
              <Button onClick={() => setIsOpenConfirmationModalDeleteMessagesWithErrors(true)} startIcon={<LabelIcon style={{ fontSize: 25 }} />}>
                <Text component="span">
                  APAGAR MENSAGENS COM ERRO
                </Text>
              </Button>
            </div>
          </div>
          <div className="mt-12">
            <Text>MENSAGENS</Text>
            <Text className="text-sm">Mostrando {messages.length} mensagens na página {page + 1}</Text>
            {
              !withinWorkingHours &&
              <div className="my-2">
                <div className={clsx('bg-red-600 bg-opacity-[1] rounded p-2 flex space-x-2')}>
                  <Text component="span">
                    <AccessTimeIcon className="text-white" style={{ fontSize: 30 }} />
                  </Text>
                  <Text className="pt-1 text-white">
                    Essa instância não está no seu expediente e horário de trabalho e não fará envios no momento. <Link to="/messages/scheduler" className="font-bold">Configurar expediente</Link>
                  </Text>
                </div>
              </div>
            }
            <div className="mt-2">
              <Collapse in={messages.length === 0 && !isLoadingMessages} timeout="auto" unmountOnExit>
                <div className="py-28 flex flex-col items-center justify-center rounded">
                  <img src="/assets/images/cat-sleeping-gray-001.svg" className="opacity-[0.3]" />
                  <Text className="mt-2 opacity-[0.5]">Não há mensagens por aqui</Text>
                  <Link to="/messages/send" className="mt-4 flex items-center space-x-2">
                    <AutoAwesomeIcon style={{ fontSize: 35, color: theme.palette.primary.main }} />
                    <Text component="span" className="font-bold">
                      ENVIAR PRIMEIRA MENSAGEM
                    </Text>
                  </Link>
                </div>
              </Collapse>
              <Paper elevation={5}>
                <div>
                  <MessageListContainer>
                    {
                      messages.map((message, index) => (
                        <MessageListItem key={index} message={message} />
                      ))
                    }
                  </MessageListContainer>
                </div>
              </Paper>
              <div className="mt-4 flex items-center space-x-4">
                <button
                  className={clsx('p-1 rounded-full bg-opacity-[0.1]', {
                    'bg-white text-white': theme.isDark,
                    'bg-gray-800 text-black': !theme.isDark,
                    'opacity-[0.3]': page === 0
                  })}
                  onClick={previousPage}
                  disabled={page === 0}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className={clsx('p-1 rounded-full bg-opacity-[0.1]', {
                    'bg-white text-white': theme.isDark,
                    'bg-gray-800 text-black': !theme.isDark
                  })}
                  onClick={nextPage}
                >
                  <ChevronRightIcon />
                </button>
                <Text className="text-xs font-black">Página {page + 1}</Text>
                {
                  isLoadingMessages &&
                  <CircularProgressCustom size={20} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function MessageListContainer({ children }) {
  return (
    <ol className="divide-y divide-[#8a8a8a37]">
      {children}
    </ol>
  )
}

function MessageListItem({ message }) {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const [file, setFile] = useState(messagesLib.getFileFromMessage(message?.message || '') || undefined)

  return (
    <li className="p-4">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6 md:col-span-2 flex space-x-1">
          <button className="h-[40px] w-[40px]" onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandMoreIcon />
          </button>
          <button
            className={clsx('bg-opacity-[0.1] rounded-full h-[40px] w-[40px]', {
              'bg-white': theme.isDark,
              'bg-gray-800': !theme.isDark
            })}
          >
            {
              message.status === 'success' && message.ack === 3
              ?
              <Tooltip title="Mensagem lida">
                <DoneAllIcon />
              </Tooltip>
              :
              message.status === 'success'
              ?
              <Tooltip title="Enviado com sucesso">
                <CheckIcon />
              </Tooltip>
              :
              message.status === 'erro'
              ?
              <Tooltip title="Com erro">
                <ReportProblemIcon className={theme.isDark ? 'text-white' : 'text-red-600'} />
              </Tooltip>
              :
              message.status === 'queue'
              ?
              <Tooltip title="Em fila">
                <WatchLaterIcon className="opacity-[0.6]" />
              </Tooltip>
              :
              <Tooltip title="Não identificado">
                <NewReleasesIcon />
              </Tooltip>
            }
          </button>
          <Collapse in={!!file} timeout="auto" unmountOnExit>
            <button className={clsx('bg-opacity-[0.1] rounded-full h-[40px] w-[40px]', {
                'bg-white': theme.isDark,
                'bg-gray-800': !theme.isDark
              })}>
              <InsertDriveFileIcon className="opacity-[0.5]" />
            </button>
          </Collapse>
        </div>
        <div className="hidden md:block col-span-3">
          <Text className="text-xs font-light">Telefone</Text>
          <Text>{message.phone}</Text>
        </div>
        <div className="hidden md:block col-span-3">
          <Text className="text-xs font-light">Criado em</Text>
          <Text>{moment(message.created_at).format('DD/MM/YYYY [às] HH:mm:ss')}</Text>
        </div>
        <div className="col-span-6 md:col-span-4 flex space-x-1 justify-end">
          <button className="bg-white bg-opacity-[0.1] rounded-full h-[40px] w-[40px]">
            <RefreshIcon />
          </button>
          <button className="bg-white bg-opacity-[0.1] rounded-full h-[40px] w-[40px]">
            <DeleteIcon />
          </button>
        </div>
        <div className="block md:hidden col-span-12 grid grid-cols-2">
          <div>
            <Text className="text-xs font-light">Telefone</Text>
            <Text>5512992302960</Text>
          </div>
          <div>
            <Text className="text-xs font-light">Criado em</Text>
            <Text>20/11/2024</Text>
          </div>
        </div>
        <div className="col-span-12">
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <div>
              {
                message.status === 'erro' &&
                <div className="bg-red-600 text-white p-1 flex space-x-2">
                  <ReportProblemIcon />
                  <span>{message?.erro?.text}</span>
                </div>
              }
              <div className="py-8">
                <div className="grid grid-cols-3">
                  <div>
                    {
                      message.ack >= 1
                      ? <ProgressDone />
                      : <ProgressWaiting />
                    }
                    <Text className="hidden md:block font-bold text-center pt-6">
                      Mensagem não recebida no device
                    </Text>
                    <Text className="block md:hidden font-bold text-center pt-6">
                      Não recebida
                    </Text>
                  </div>
                  <div>
                    {
                      message.ack >= 2
                      ? <ProgressDone />
                      : <ProgressWaiting />
                    }
                    <Text className="hidden md:block font-bold text-center pt-6">
                      Mensagem recebida no device
                    </Text>
                    <Text className="block md:hidden font-bold text-center pt-6">
                      Recebida
                    </Text>
                    {
                      message.receivedAt &&
                      <Text className="text-center">
                        {moment(message.receivedAt).format('DD/MM/YYYY [às] HH:mm:ss')}
                      </Text>
                    }
                  </div>
                  <div>
                    {
                      message.ack >= 3
                      ? <ProgressDone />
                      : <ProgressWaiting />
                    }
                    <Text className="hidden md:block font-bold text-center pt-6">
                      Mensagem lida
                    </Text>
                    <Text className="block md:hidden font-bold text-center pt-6">
                      Lida
                    </Text>
                    {
                      message.readAt &&
                      <Text className="text-center">
                        {moment(message.readAt).format('DD/MM/YYYY [às] HH:mm:ss')}
                      </Text>
                    }
                  </div>
                </div>
              </div>
              {
                file &&
                <div className={clsx('flex items-center space-x-2 mb-4 p-2 rounded bg-opacity-[0.1]', {
                  'bg-white': theme.isDark,
                  'bg-gray-800': !theme.isDark
                })}>
                  <InsertDriveFileIcon style={{ fontSize: 30, opacity: 0.5 }} />
                  <div>
                    <span className="block font-ligh text-sm">{file.filename}</span>
                    <a className="block font-medium" href={file.url} target="_blank">{file.url}</a>
                  </div>
                </div>
              }
              <Paper elevation={8}>
                <div
                  className="p-12"
                  style={{
                    backgroundImage: theme.isDark ? `url(${WpBgDark})` : `url(${WpBgLight})`
                  }}
                >
                  <div
                    className={clsx('w-[80%] md:w-[60%] h-full rounded p-4', {
                      'shadow-md bg-[#005c4bff]': theme.isDark,
                      'bg-[#ecebeb]': !theme.isDark
                    })}
                  >
                    {messagesLib.clearMessage(message.message)}
                  </div>
                </div>
              </Paper>
            </div>
          </Collapse>
        </div>
      </div>
    </li>
  )
}

function ProgressDone() {
  return (
    <div className="relative">
      <div className="relative h-[10px] w-full bg-[#00ccff]">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow w-[40px] h-[40px] rounded-full bg-[#00ccff] flex justify-center items-center">
          <CheckIcon style={{ color: 'white' }} />
        </div>
      </div>
    </div>
  )
}

function ProgressWaiting() {
  const theme = useTheme()

  return (
    <div className="relative">
      <div
        className={clsx('relative h-[10px] w-full bg-opacity-[0.1]', {
          'bg-white': theme.isDark,
          'bg-gray-900': !theme.isDark
        })}
      >
        <div className={clsx('absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[40px] h-[40px] rounded-full', {
          'shadow bg-[#67676a]': theme.isDark,
          'bg-[#cacaca]': !theme.isDark
        })}>

        </div>
      </div>
    </div>
  )
}
