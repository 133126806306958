import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  input: {
    '& fieldset': {
      borderWidth: 2,
      borderColor: theme.isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.9)'
    },
    '& input + div button svg': {
      color: theme.isDark ? 'white' : 'inherit'
    }
  }
}))

export default function TextFieldCustom({ InputProps, children, ...props }) {
  const classes = useStyles()

  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      slotProps={{
        input: {
          className: classes.input,
          ...InputProps
        }
      }}
      {...props}
    >
      {children}
    </TextField>
  )
}
