import React from 'react'
import { Typography } from '@mui/material'

export default function Text({ children, component = 'div', ...props }) {
  return (
    <Typography color="textPrimary" component={component}>
      <p {...props}>{ children }</p>
    </Typography>
  )
}
