import React, { useContext, useEffect, useState } from 'react'
import { Button, Paper, Radio, Snackbar, useTheme } from '@mui/material'
import delay from 'delay'
import clsx from 'clsx'
import Collapse from '@mui/material/Collapse'
import ConstructionIcon from '@mui/icons-material/Construction'
import IllustrationAbstract001 from '../../assets/illustration-abstract-001.png'
import hipersendService from '../../services/hipersend'
import { ApiDataWrapperContext } from '../../contexts/ApiDataWrapper'
import Text from '../Text'
import TextFieldCustom from '../TextFieldCustom'

export default function Settings() {
  const theme = useTheme()
  const { api, secret } = useContext(ApiDataWrapperContext)

  const [disconnectionNotificationNumber, setDisconnectionNotificationNumber] = useState('')
  const [disconnectionNotificationNumberLoading, setDisconnectionNotificationNumberLoading] = useState('')
  const [dailyReportNotificationNumber, setDailyReportNotificationNumber] = useState('')
  const [dailyReportNotificationNumberLoading, setDailyReportNotificationNumberLoading] = useState('')
  const [messageTimer, setMessageTimer] = useState(undefined)
  const [messageTimerLoading, setMessageTimerLoading] = useState(false)
  const [messageTimerNewLine, setMessageTimerNewLine] = useState(undefined)
  const [plan, setPlan] = useState(undefined)
  const [showCardHeader, setShowCardHeader] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState('')

  const handleShowComponents = async () => {
    setShowCardHeader(true)
    await delay(300)
    setShowCard(true)
  }

  const getInformations = async () => {
    try {
      if(!api || !secret) return
      const response = await hipersendService.notificacoesInteligentes.getInformations(api, secret)

      setDisconnectionNotificationNumber(response.info.me.user)
      setDailyReportNotificationNumber(response.dailyReportNumber)
      setPlan(response.user.plan)
      setMessageTimer(response.timerOverride || response.user.plan.timer)
      setMessageTimerNewLine(response.timerNewLine)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeDisconnectionNotificationNumber = async () => {
    try {
      if(!api || !secret) return

      setDisconnectionNotificationNumberLoading(true)
      await hipersendService.notificacoesInteligentes.changeDisconnectionNotificationNumber(api, secret, disconnectionNotificationNumber)

      await delay(800)
      setDisconnectionNotificationNumberLoading(false)
  
      setMessageSuccess('Número de desconexão atualizado')
    } catch (error) {
      console.error(error)
      setDisconnectionNotificationNumberLoading(false)
    }
  }

  const handleChangeDailyReportNotificationNumber = async () => {
    try {
      if(!api || !secret) return

      setDailyReportNotificationNumberLoading(true)
      await hipersendService.notificacoesInteligentes.changeDailyReportNotificationNumber(api, secret, dailyReportNotificationNumber)

      await delay(800)
      setDailyReportNotificationNumberLoading(false)
  
      setMessageSuccess('Número de relatório diário atualizado')
    } catch (error) {
      console.error(error)
      setDailyReportNotificationNumberLoading(false)
    }
  }

  const handleChangeMessageTimer = async () => {
    try {
      if(!api || !secret) return
      if(messageTimer < plan.timer) return

      setMessageTimerLoading(true)

      const data = {
        timerOverride: messageTimer
      }

      if(plan?.functionalities?.includes('NewLine')) {
        const messageTimerNewLineDefault = plan.timer
        data.timerNewLine = messageTimerNewLineDefault

        if(messageTimerNewLine && messageTimerNewLine >= 2 && messageTimerNewLine <= 60) {
          data.timerNewLine = messageTimerNewLine
        }
      }
      
      await hipersendService.notificacoesInteligentes.changeMessageTimer(api, secret, data)

      await delay(800)
      setMessageTimerLoading(false)

      setMessageSuccess('Tempo de envio atualizado')
    } catch (error) {
      console.error(error)
      setMessageTimerLoading(false)
    }
  }

  useEffect(() => {
    handleShowComponents()
  }, [])

  useEffect(() => {
    getInformations()
  }, [api, secret])

  return (
    <div className="relative mb-32">
      <Snackbar
        open={messageSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setMessageSuccess('')}
        message={messageSuccess}
      />
      <div
        className={clsx('absolute top-0 left-0 w-full h-[400px] ', {
          'bg-opacity-[0.02] bg-white': theme.isDark,
          'bg-opacity-[0.05] bg-gray-800': !theme.isDark
        })}
      />
      <div
        className={clsx('absolute top-0 left-0 w-full h-[550px]', {
          'bg-opacity-[0.02] bg-white': theme.isDark,
          'bg-opacity-[0.05] bg-gray-800': !theme.isDark
        })}
      />
      <div className="relative max-w-[750px] w-full mx-auto py-12 md:py-32">
        <Paper elevation={5}>
          <Paper elevation={1}>
            <Collapse in={showCardHeader} timeout="auto" unmountOnExit>
              <Paper elevation={0} square>
                <div className="px-8 py-3">
                  <img src={IllustrationAbstract001} />
                </div>
              </Paper>
            </Collapse>
            <Collapse in={showCard} timeout="auto" unmountOnExit>
              <div className="p-6">
                <Text className="text-lg md:text-xl font-medium">
                  Configurações
                </Text>
                <Text className="font-light text-sm">
                  Configure sua api de acordo com as necessidades da sua empresa
                </Text>
                <div className="mt-12 divide-y-2 divide-[#8a8a8a37]">
                  <div className="py-6">
                    <div>
                      <Text className="font-bold">
                        Notificação de desconexão
                      </Text>
                      <Text className="max-w-[400px] font-light text-sm">
                        Quando sua instância estiver desconectada, vamos te avisar atráves do número cadastrado.
                      </Text>
                    </div>
                    <div className="mt-4 max-w-[350px]">
                      <TextFieldCustom
                        label="Número de desconexão"
                        value={disconnectionNotificationNumber}
                        onChange={e => setDisconnectionNotificationNumber(e.target.value)}
                        disabled={disconnectionNotificationNumberLoading}
                        helperText="Certifique-se de adicionar ddi e ddd da sua região"
                      />
                      <div className="mt-2">
                        <Button
                          color="inherit"
                          onClick={handleChangeDisconnectionNotificationNumber}
                          disabled={disconnectionNotificationNumberLoading}
                        >
                          {
                            disconnectionNotificationNumberLoading
                            ? 'SALVANDO'
                            : 'SALVAR'
                          }
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="py-6">
                    <div>
                      <Text className="font-bold">
                        Notificação diária do relatório de envio
                      </Text>
                      <Text className="max-w-[400px] font-light text-sm">
                        Todo dia às 17:00, um relatório sobre sua API será enviado ao número definido abaixo.
                      </Text>
                    </div>
                    <div className="mt-4 max-w-[350px]">
                      <TextFieldCustom
                        label="Número de notificação"
                        onChange={e => setDailyReportNotificationNumber(e.target.value)}
                        value={dailyReportNotificationNumber}
                        helperText="Certifique-se de adicionar ddi e ddd da sua região"
                      />
                      <div className="mt-2">
                        <Button
                          color="inherit"
                          onClick={handleChangeDailyReportNotificationNumber}
                          disabled={dailyReportNotificationNumberLoading}
                        >
                          {
                            dailyReportNotificationNumberLoading
                            ? 'SALVANDO'
                            : 'SALVAR'
                          }
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="py-6 opacity-[0.4]">
                      <div>
                        <Text className="font-bold">
                          Tema
                        </Text>
                        <Text className="font-light text-sm">
                          Defina a cor do sistema
                        </Text>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <Radio />
                          <Text>Claro</Text>
                        </div>
                        <div className="flex items-center space-x-4">
                          <Radio />
                          <Text>Escuro</Text>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                      <div className="flex items-center space-x-2 p-1 bg-yellow-400">
                        <ConstructionIcon className="text-black" style={{ fontSize: 45 }} />
                        <Text className="font-black text-2xl text-black">FEATURE EM DESENVOLVIMENTO</Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="py-6">
                      <div>
                        <Text className="font-bold">
                          Tempo de envio das mensagens
                        </Text>
                        <Text className="max-w-[400px] font-light text-sm">
                          Defina um limite personalizado de envio das mensagens da api
                        </Text>
                      </div>
                      <div className="mt-4">
                        <TextFieldCustom
                          label="Tempo em segundos"
                          className="max-w-[180px]"
                          value={messageTimer}
                          type="number"
                          onChange={e => {
                            const number = Number(e.target.value)
                            if(Number.isNaN(number)) {
                              return
                            }

                            if(number < plan.timer) {
                              return
                            }

                            setMessageTimer(number)
                          }}
                          disabled={messageTimerLoading}
                        />
                        <Text className="text-xs">
                          Tempo mínimo do seu plano atual é de {plan?.timer}s.
                        </Text>
                      </div>
                    </div>
                    {
                      plan?.functionalities?.includes('NewLine') &&
                      <div className="py-6">
                        <div>
                          <Text className="font-bold">
                            Tempo de envio fracionado das mensagens
                          </Text>
                          <Text className="max-w-[400px] font-light text-sm">
                            Defina um limite personalizado de envio das mensagens da api
                          </Text>
                        </div>
                        <div className="mt-4">
                          <TextFieldCustom
                            label="Tempo em segundos"
                            className="max-w-[180px]"
                            type="number"
                            onBlur={() => {
                              if(!messageTimerNewLine) {
                                setMessageTimerNewLine(plan.timer)
                              }
                            }}
                            onChange={e => {
                              const number = Number(e.target.value)
                              if(number === 0) {
                                setMessageTimerNewLine(undefined)
                                return
                              }

                              if(Number.isNaN(number)) {
                                return
                              }
    
                              if(number < 2 || number > 60) {
                                return
                              }
    
                              setMessageTimerNewLine(number)
                            }}
                            value={messageTimerNewLine}
                            disabled={messageTimerLoading}
                          />
                          <Text className="text-xs max-w-[400px]">
                            Esse é o tempo fracionado entre as mensagens do %NewLine%. O Tempo mínimo é 2s, e máximo é de 60s.
                          </Text>
                        </div>
                      </div>
                    }
                    <div className="mt-2">
                      <Button
                        color="inherit"
                        onClick={handleChangeMessageTimer}
                        disabled={messageTimerLoading}
                      >
                        {
                          messageTimerLoading
                          ? 'SALVANDO'
                          : 'SALVAR'
                        }
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </Paper>
        </Paper>
      </div>
    </div>
  )
}
