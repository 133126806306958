import React, { createContext, useEffect, useState } from 'react'
import hipersendService from '../../services/hipersend'

const ApiDataWrapperContext = createContext()

const ApiDataWrapperProvider = ({ children }) => {
  const [api, setApi] = useState('')
  const [secret, setSecret] = useState('')
  const [server, setServer] = useState('')
  const [isSuspended, setIsSuspended] = useState(undefined)
  const [phone, setPhone] = useState('')

  const getCredentials = () => {
    const api = window.localStorage.getItem('api')
    const secret = window.localStorage.getItem('secret')

    return { api, secret }
  }

  const fetchData = async () => {
    try {
      if(phone && server && isSuspended !== undefined) {
        return
      }

      const response = await hipersendService.notificacoesInteligentes.getInformations(api, secret)

      setServer(response.numberServer)
      setIsSuspended(response.isSuspended)
      setPhone(response.info.me.user)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(!api || !secret) {
      return
    }

    window.localStorage.setItem('api', api)
    window.localStorage.setItem('secret', secret)
  }, [api, secret])

  useEffect(() => {
    if(!api || !secret) return
    fetchData()
  }, [api, secret])

  return (
    <ApiDataWrapperContext.Provider
      value={{
        api,
        setApi,
        secret,
        setSecret,
        getCredentials,
        isSuspended,
        setIsSuspended,
        server,
        setServer,
        phone,
        setPhone
      }}
    >
      {children}
    </ApiDataWrapperContext.Provider>
  )
}

export {
  ApiDataWrapperContext,
  ApiDataWrapperProvider
}
