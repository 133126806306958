import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthProvider } from '../contexts/Auth'
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material'

const themeDark = createTheme({
  palette: {
    mode: 'dark',
    textPrimary: '#39ACE7',
    background: {
      main: '#26272aff',
      default: '#26272aff',
      paper: '#2a2b2eff'
    },
    primary: {
      main: '#02b7fb'
    }
  },
  isDark: true
})

const themeLight = createTheme({
  palette: {
    mode: 'light',
    textPrimary: '#00ccffff',
    background: {
      main: '#fff',
      default: '#fff',
      paper: '#fff'
    },
    primary: {
      main: '#02b7fb'
    }
  },
  isDark: false
})

export default function LayoutBase({ children }) {
  const [queryParameters] = useSearchParams()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const preferredTheme = window.localStorage.getItem('preferredTheme')
  const [themeMode, setThemeMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? 'dark' : 'light')
  const [currentTheme, setCurrentTheme] = useState(themeMode === 'light' ? themeLight : themeDark)

  useEffect(() => {
    window.localStorage.setItem('preferredTheme', themeMode)
    setCurrentTheme(themeMode === 'light' ? themeLight : themeDark)
  }, [themeMode])

  useEffect(() => {
    const themeMode = queryParameters.get('theme')
    if(!['dark', 'light'].includes(themeMode)) return
    setThemeMode(themeMode)
  }, [queryParameters.get('theme')])

  return (
    <ThemeProvider theme={currentTheme}>
      <AuthProvider>
        {children}
      </AuthProvider>
    </ThemeProvider>
  )
}
