import Routes from './routes'
import './App.css'
import { ApiDataWrapperProvider } from './contexts/ApiDataWrapper'

function App() {
  return (
    <ApiDataWrapperProvider>
      <Routes />
    </ApiDataWrapperProvider>
  )
}

export default App
