import React from 'react'
import LayoutMain from '../layouts/Main'
import LayoutBase from '../layouts/Base'
import Settings from '../components/Settings'

export default function SettingsPage() {
  return (
    <LayoutBase>
      <LayoutMain>
        <Settings />
      </LayoutMain>
    </LayoutBase>
  )
}
